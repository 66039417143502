import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";
// import PTop from "../../../assets/PatternTop.svg";
import Logo from "../../assets/Logo.png";
import HomeIcon from "../../assets/HomeIcon.svg";
import StudentIcon from "../../assets/StudentIcon.svg";
import EducationHatIcon from "../../assets/EducationHatIcon.svg";
import MessageIcon from "../../assets/MessageIcon.svg";
import SettingIcon from "../../assets/SettingIcon.svg";
import {
  HiChevronDown,
  HiMail,
  HiBell,
  HiMenu,
  HiLogout,
  HiXCircle,
  HiChevronUp,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import { getMyCourses } from "../../api/course.api";

const getIsMobile = () => window.innerWidth <= 768;

function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(getIsMobile());
  React.useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
}
export default function Home() {
  const { isAuth, onLogout, user } = useContext<any>(AuthContext);
  const [myCourses, setMyCourses] = React.useState([]);
  const navigate = useNavigate();
  const onMyCourses = async () => {
    const _c = await getMyCourses();
    setMyCourses(_c.data);
  };

  const isMobile = useIsMobile();
  React.useEffect(() => {
    if (isAuth === null) {
      return;
    }
    if (isAuth === false) {
      return navigate("/auth/login");
    }
    onMyCourses();

    //eslint-disable-next-line
  }, [isAuth]);

  const isCourseView = () => {
    if (window.location.pathname.startsWith("/courses")) {
      return true;
    }
    return false;
    // return true
  };

  React.useEffect(() => {
    if (isMobile) {
      setSidebar(false);
    }
  }, [isMobile]);

  const [showSidebar, setSidebar] = React.useState(false);
  const [toggle, setToggle] = React.useState(true);

  return isCourseView() ? (
    <Outlet context={[onMyCourses]}  />
  ) : (
    <div className="flex flex-row min-h-screen max-h-screen">
      <div
        className={`bg-[#151515] min-w-[320px] ${
          isMobile && showSidebar ? "block " : "hidden"
        } md:block overflow-auto ${
          isMobile ? "fixed top-0 h-full left-0 z-[99999]" : null
        }`}
      >
        <div className="h-[150px] flex items-center justify-center">
          <img src={Logo} alt="logo" className="w-[180px]" />
        </div>
        {isMobile ? (
          <button
            className="absolute top-3 right-3"
            onClick={() => setSidebar(false)}
          >
            <HiXCircle className="w-7 h-7" />
          </button>
        ) : null}
        <hr className="opacity-10" />
        <ul className="pt-8">
          <li
            className={`${
              window.location.pathname === "/"
                ? "border-r-4 border-primary bg-[#000]"
                : "border-t border-[#ffffff10]"
            } text-md`}
          >
            <Link
              to="/"
              className="py-4  px-5 flex items-center"
              onClick={() => setSidebar(false)}
            >
              <img src={HomeIcon} alt="logo" className="w-[24px] mr-3" /> Portal
              Dashboard
            </Link>
          </li>
          <li
            className={`${
              window.location.pathname.startsWith("/courses")
                ? "border-r-4 border-primary bg-[#000]"
                : "border-t border-[#ffffff10]"
            } text-md`}
          >
            <Link
              to="/courses"
              className="py-4  px-5 flex items-center"
              onClick={() => setSidebar(false)}
            >
              <img src={StudentIcon} alt="logo" className="w-[24px] mr-3" />
              Education
            </Link>
          </li>
          {/* <li className="border-t border-[#ffffff10] px-5 text-md flex justify-between items-center ">
            <Link
              to="#"
              className="py-4 flex items-center flex-grow"
              onClick={() => setSidebar(false)}
            >
              <img
                src={EducationHatIcon}
                alt="logo"
                className="w-[24px] mr-3"
              />
              My Courses
            </Link>

            <button onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <HiChevronDown className="text-primary text-lg" />
              ) : (
                <HiChevronUp className="text-primary text-lg" />
              )}
            </button>
          </li>
          {toggle &&
            myCourses.map((c: any) => (
              <li className="border-t border-[#ffffff10]  flex items-center text-md">
                <Link
                  to={`/courses/${c.course.courseID}`}
                  className="py-3 flex items-center flex-grow pr-5 pl-16"
                  onClick={() => setSidebar(false)}
                >
                  {c?.course?.title}
                </Link>
              </li>
            ))} */}
          {/* <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
            Cryptocurrency
          </li> */}
          {/* <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
            Blockchain
          </li>
          <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
            NFTs
          </li>
          <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
            Mining
          </li> */}
          <li
            className={`${
              window.location.pathname.startsWith("/message")
                ? "border-r-4 border-primary bg-[#000]"
                : "border-t border-[#ffffff10]"
            } text-md`}
          >
            <Link
              to="/message"
              className="py-4  px-5 flex items-center"
              onClick={() => setSidebar(false)}
            >
              <img src={MessageIcon} alt="logo" className="w-[24px] mr-3" />
              Message Board
            </Link>
          </li>

          <li
            className={`${
              window.location.pathname.startsWith("/settings")
                ? "border-r-4 border-primary bg-[#000]"
                : "border-t border-[#ffffff10]"
            } text-md`}
          >
            <Link
              to="/settings"
              className="py-4  px-5 flex items-center"
              onClick={() => setSidebar(false)}
            >
              <img src={SettingIcon} alt="logo" className="w-[24px] mr-3" />
              Settings
            </Link>
          </li>
        </ul>
        <hr className="opacity-10" />
      </div>
      <div className="flex-grow  max-h-screen overflow-auto px-8">
        <div className="py-8 px-3 md:px-0 flex flex-row justify-center items-center">
          {isMobile ? (
            <div className="flex-grow flex "></div>
          ) : (
            <div className="flex-grow flex flex-row  items-center">
              <div className="rounded-full w-[36px] h-[36px] shadow shadow-primary mr-3">
                <img
                  src={
                    user?.avatar ??
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                  }
                  alt="profile"
                  className="object-cover w-[36px] h-[36px] rounded-full"
                />
              </div>
              welcome{" "}
              <span className="text-primary ml-2">{user?.firstName}</span>
            </div>
          )}

          <div className="flex justify-end items-center">
            <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
              <HiMail className="text-primary text-[24px]" />
            </button>
            <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
              <HiBell className="text-primary text-[24px]" />
            </button>
            <button
              className="bg-primary mx-2 py-2 px-5 rounded-3xl text-white capitalize font-bold hidden md:inline-block"
              onClick={onLogout}
            >
              Logout
            </button>
            <button
              className="bg-primary mx-2 w-8 h-8 p-2 rounded-full text-white capitalize font-bold md:hidden inline-block"
              onClick={onLogout}
            >
              <HiLogout className=" text-[16px]" />
            </button>
            <button className="p-3 md:hidden " onClick={() => setSidebar(true)}>
              <HiMenu className=" text-[16px]" />
            </button>
          </div>
        </div>
        <Outlet context={[onMyCourses]} />
      </div>
    </div>
  );
}
