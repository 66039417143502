import { axiosInstance } from "./api";

export const getRecentBoard = async () => {
  return await axiosInstance.get("/board");
};
export const getABoard = async (id: string | any) => {
  return await axiosInstance.get(`/board/${id}`);
};
export const postANewBoard = async (data: any) => {
  return await axiosInstance.post(`/board/`, data);
};
export const deleteABoard = async (id: string) => {
  return await axiosInstance.delete(`/board/${id}`);
};
export const updatedBoard = async (id: string, data: any) => {
  return await axiosInstance.patch(`/board/${id}`, data);
};
export const likeBoard = async (id: string) => {
  return await axiosInstance.post(`/board/${id}/like`);
};
export const answerABoard = async (data: any, id: string | any) => {
  return await axiosInstance.post(`/board/${id}/`, data);
};
export const deleteAnswer = async (id: string, answerID: string) => {
  return await axiosInstance.delete(`/board/${id}/${answerID}/`);
};
export const updateAnswer = async (id: string, answerID: string, data: any) => {
  return await axiosInstance.patch(`/board//${id}/${answerID}/`, data);
};
