import React from "react";
import { getAllUser } from "../../../api/user.api";
import { toast } from "react-toastify";
import { HiOutlineEye, HiOutlineUserRemove, HiOutlineUsers, HiUserRemove } from "react-icons/hi";

type Props = {};

export default function Users({}: Props) {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const initCall = async () => {
    try {
      const users = await getAllUser();
      setLoading(false);
      setUsers(users.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  React.useEffect(() => {
    initCall();
  }, []);

  return (
    <div>
      <div className="overflow-hidden rounded-lg border border-gray-900 shadow-md ">
        <table className="w-full border-collapse bg-white text-left text-sm text-gray-900">
          <thead className="bg-gray-400">
            <tr>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Name
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                State
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Email
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Phone
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-right text-gray-900">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 border-t border-gray-100">
            {users.map((u: any, i: number) => (
              <tr className="hover:bg-gray-50">
                <th
                  className="flex gap-3 px-6 py-4 font-normal text-gray-900"
                  key={i}
                >
                  <div className="relative h-10 w-10">
                    <img
                      className="h-full w-full rounded-full object-cover object-center"
                      src={
                        u.avatar ??
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                      }
                      alt=""
                    />
                    {/* <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span> */}
                  </div>
                  <div className="text-sm">
                    <div className="font-medium text-gray-700">
                      {u.firstName} {u.lastName}
                    </div>
                    <div className="text-gray-400">{u.nickName ?? "-"}</div>
                  </div>
                </th>
                <td className="px-6 py-4">
                  {u.status === 1 && (
                    <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                      <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                      Active
                    </span>
                  )}
                  {u.status === 2 && (
                    <span className="inline-flex items-center gap-1 rounded-full bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-600">
                      <span className="h-1.5 w-1.5 rounded-full bg-yellow-600"></span>
                      Inactive
                    </span>
                  )}
                </td>
                <td className="px-6 py-4">{u.email}</td>
                <td className="px-6 py-4 text-black">
                  {u.phone ?? "-"}
                  {/* <div className="flex gap-2">
                    <span className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600">
                      Design
                    </span>
                    <span className="inline-flex items-center gap-1 rounded-full bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600">
                      Product
                    </span>
                    <span className="inline-flex items-center gap-1 rounded-full bg-violet-50 px-2 py-1 text-xs font-semibold text-violet-600">
                      Develop
                    </span>
                  </div> */}
                </td>
                <td className="px-6 py-4">
                  <div className="flex justify-end gap-4">
                    <button>
                      <HiOutlineUserRemove className="h-5 w-5"/>
                    </button>
                    <button>
                      <HiOutlineEye className="h-5 w-5"/>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
