import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";

export default function Auth() {
  const { isAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  return (

    <Outlet />

  );
}
