import { useState, createContext, useEffect } from "react";
import { getMyself } from "../api/user.api";

export const AuthContext = createContext({});
export default function AuthContextWrapper({ children }: any) {
  const [user, setUser] = useState();
  const [hasToken, setHasToken] = useState<boolean | any>(null);
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  // const [token, setToken] = useState<any>(null);

  const getAuthToken = async () => {
    const _token = localStorage.getItem("token");
    if (_token === null) {
      setIsAuth(false);
      setHasToken(false);
      return;
    }
    setHasToken(true);
    // setToken(_token);
  };

  const getMySelf = async () => {
    if (hasToken === false || hasToken) {
      return;
    }
    try {
      const user = await getMyself();
      setUser(user.data);
      setIsAuth(true);
    } catch (error) {}
  };
  useEffect(() => {
    getAuthToken();
  }, []);

  const onLogout = () => {
    localStorage.removeItem("token");
    setIsAuth(false);
    setHasToken(false);
  };

  useEffect(() => {
    getMySelf();

    //eslint-disable-next-line
  }, [hasToken]);

  return (
    <AuthContext.Provider
      value={{ isAuth, setIsAuth, setHasToken, hasToken, onLogout, user }}
    >
      {setIsAuth === null ? "Loading..." : children}
    </AuthContext.Provider>
  );
}
