import React, { useEffect, useState } from "react";
import Camera from "../../../assets/CameraProfile.svg";
import OverlayBtn from "../../../assets/OverlayBtn.svg";
import { HiPencilAlt } from "react-icons/hi";
import { ReactComponent as Underline } from "../../../assets/Underline.svg";
import { useNavigate } from "react-router-dom";
import { getMyself, uploadProfilePicture } from "../../../api/user.api";
import { toast } from "react-toastify";
import { getAllCourses , getMyCourses } from "../../../api/course.api";

export default function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [courses, setCourses] = React.useState([]);

  const onMyCourses = async () => {
    // const _c = await getMyCourses();
    // setMyCourses(_c.data);
    const _c = await getAllCourses();
    setCourses(_c.data);
  };
  const getMyselfInfo = async () => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      const _u = await getMyself();
      setUser(_u.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMyselfInfo();
    onMyCourses();
  }, []);

  const handleFileUpload = async (event: any) => {
    // get the selected file from the input
    const file = event.target.files[0];
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file);
    try {
      await uploadProfilePicture(formData);
      toast.success("Profile Picture Updated");
      getMyselfInfo();
    } catch (error: any) {
      toast.error(error.response.data.message ?? "Something Went Wrong");
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="lg:col-span-3 col-span-12 flex justify-center items-center">
          <div className="rounded-full w-[150px] h-[150px] shadow-lg shadow-[#10CC0090] mr-3 relative bg-black">
            <img
              src={
                user?.avatar ??
                "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
              }
              alt="profile"
              className="object-cover w-[150px] h-[150px] rounded-full"
            />
            <input
              type="file"
              id="btn"
              name="file"
              className="hidden"
              onChange={handleFileUpload}
              accept="image/*"
              // style="display: none;"
            />
            <label
              htmlFor="btn"
              className="absolute bottom-1 right-1 cursor-pointer"
            >
              {/* <button> */}
              <img src={Camera} alt="" />
              {/* </button> */}
            </label>
          </div>
        </div>
        <div className="lg:col-span-9 col-span-12 font-light border border-primary rounded-lg md:border-0 flex items-center flex-col pb-5 pt-16 md:block my-[-50px]">
          <div className="md:py-3 md:px-5  flex justify-between items-center md:flex-row flex-col ">
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">Nick Name:</span> {user?.nickName}
            </div>
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">Email:</span> {user?.email}
            </div>
          </div>
          <hr className="md:border-primary hidden md:block opacity-20" />
          <div className="md:py-3 md:px-5  flex justify-between items-center md:flex-row flex-col">
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">First Name: </span>{" "}
              {user?.firstName}
            </div>
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">Last Name</span> {user?.lastName}
            </div>
          </div>
          <hr className="md:border-primary hidden md:block opacity-20" />
          <div className="md:py-3 md:px-5  flex justify-between items-center md:flex-row flex-col">
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">Phone Number: </span>
              {user?.phone}
            </div>
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">Address:</span> {user?.address}
            </div>
          </div>
          <hr className="md:border-primary hidden md:block opacity-20" />
          <div className="md:py-3 md:px-5  flex justify-between items-center md:flex-row flex-col">
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">Country:</span> USA
            </div>
            <div className="md:w-[50%] mb-5 md:mb-0">
              <span className="font-semibold">Language:</span> {user?.language}
            </div>
          </div>
          <div className="py-3">
            <button
              className="bg-primary py-2 px-5 rounded-3xl text-white capitalize font-bold"
              onClick={() => navigate("/settings")}
            >
              <HiPencilAlt className="inline " /> EDIT PROFILE
            </button>
          </div>
        </div>
      </div>
      <hr className="border-primary opacity-20 my-10" />
      <div>
        <h1 className="text-2xl font-bold mb-3 mt-5">Available Courses</h1>
        <Underline className="mb-5" />
        {/* <div className="bg-[#151515] grid grid-cols-4 mt-16">
          <button
            className="text-center w-full py-5 px-2 font-bold"
            style={{
              background: `url(${OverlayBtn})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div>Cryptocurrency</div>
          </button>
          <button className="py-3">
            <div className="border-r border-primary text-center w-full py-3 px-2">
              Blockchain
            </div>
          </button>
          <button className="py-3">
            <div className="border-r border-primary text-center w-full py-3 px-2">
              NFTs
            </div>
          </button>
          <button className="text-center w-full py-3">
            <div>Mining</div>
          </button>
        </div> */}
        {courses.map((dt: any, i) => (
          <div
            className="rounded-md border border-primary bg-black flex flex-row my-5"
            key={i}
          >
            <div className="md:w-[120px] w-[70px] p-2">
              <div className="md:w-[100px] w-[60px] md:h-[100px] h-[60px] bg-primary rounded">
                <img
                  className="object-cover md:w-[100px] w-[60px] md:h-[100px] h-[60px]" 
                  src={dt?.poster}
                  alt={dt?.title}
                />
              </div>
            </div>
            <div className="flex-grow p-3">
              <h4 className="text-lg font-bold">{dt.title}</h4>
              <h6 className="text-base opacity-40">{dt.description}</h6>
              {/* {JSON.stringify(dt)} */}
              <p className="pt-3 text-xs">
                {dt?.tags &&
                  dt?.tags((tg: string, i: number) => (
                    <span className="mr-5 text-[#A9ABA9]" key={i}>
                      {tg}
                    </span>
                  ))}
                {/* <span className="mr-5 text-[#A9ABA9]">Function</span>
                <span className="mr-5 text-[#A9ABA9]"> Applications</span> */}
              </p>
              <div className="p-3 md:hidden block">
                <button
                  onClick={() => navigate(`/courses/`)}
                  className="bg-primary py-2 px-5 rounded-3xl text-white capitalize text-xs font-bold"
                >
                  Preview
                </button>
              </div>
            </div>
            <div className="p-3 hidden md:block">
              <button
                onClick={() => navigate(`/courses/`)}
                className="bg-primary py-2 px-5 rounded-3xl text-white capitalize text-sm font-bold"
              >
                Preview
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
    // <div className="flex flex-row min-h-screen max-h-screen">
    //   <div className="bg-[#151515] min-w-[320px] flex-col">
    //     <div className="h-[150px] flex items-center justify-center">
    //       <img src={Logo} alt="logo" className="w-[180px]" />
    //     </div>
    //     <hr className="opacity-10" />
    //     <ul className="pt-8">
    //       <li>
    //         <li className="border-r-4 border-primary text-md bg-[#000] ">
    //           <NavLink to="/" className="py-4 px-5 flex items-center">
    //             <img src={HomeIcon} alt="logo" className="w-[24px] mr-3" />
    //             Portal Dashboard
    //           </NavLink>
    //         </li>
    //       </li>
    //       <li className="border-t border-[#ffffff10] text-md">
    //         <Link to="/courses" className="py-4  px-5 flex items-center">
    //           <img src={StudentIcon} alt="logo" className="w-[24px] mr-3" />
    //           Education
    //         </Link>
    //       </li>
    //       <li className="border-t border-[#ffffff10] px-5 text-md flex justify-between items-center ">
    //         <Link to="/my-course" className="py-4 flex items-center flex-grow">
    //           <img
    //             src={EducationHatIcon}
    //             alt="logo"
    //             className="w-[24px] mr-3"
    //           />
    //           My Courses
    //         </Link>

    //         <button>
    //           <HiChevronDown className="text-primary text-lg" />
    //         </button>
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Cryptocurrency
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Blockchain
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         NFTs
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Mining
    //       </li>
    //       <li className="border-t border-[#ffffff10] text-md">
    //         <Link to="/message" className="py-4  px-5 flex items-center">
    //           <img src={MessageIcon} alt="logo" className="w-[24px] mr-3" />
    //           Message Board
    //         </Link>
    //       </li>
    //       <li className="border-t border-[#ffffff10] text-md">
    //         <Link to="/settings" className="py-4  px-5 flex items-center">
    //           <img src={SettingIcon} alt="logo" className="w-[24px] mr-3" />
    //           Setting{" "}
    //         </Link>
    //       </li>
    //     </ul>
    //     <hr className="opacity-10" />
    //   </div>
    //   <div className="flex-grow  max-h-screen overflow-auto px-8">
    //     <img src={PTop} alt="" className="absolute right-0 top-0 h-[450px]" />
    //     <div className="py-8 flex flex-row justify-center items-center">
    //       <div className="flex-grow flex flex-row  items-center">
    //         <div className="rounded-full w-[36px] h-[36px] shadow shadow-primary mr-3">
    //           <img
    //             src={
    //               user?.avatar ??
    //               "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
    //             }
    //             alt="profile"
    //             className="object-cover w-[36px] h-[36px]"
    //           />
    //         </div>
    //         welcome{" "}
    //         <span className="text-primary ml-2 capitalize">
    //           {user?.firstName}
    //         </span>
    //       </div>
    //       <div className="flex justify-end items-center">
    //         <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
    //           <HiMail className="text-primary text-[24px]" />
    //         </button>
    //         <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
    //           <HiBell className="text-primary text-[24px]" />
    //         </button>
    //         <button className="bg-primary mx-2 py-2 px-5 rounded-3xl text-white capitalize font-bold">
    //           Logout
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
