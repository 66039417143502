import React, { Fragment, useContext } from "react";
// import PTop from "../../../assets/PatternTop.svg";
// import Logo from "../../../assets/Logo.png";
// import HomeIcon from "../../../assets/HomeIcon.svg";
// import StudentIcon from "../../../assets/StudentIcon.svg";
// import EducationHatIcon from "../../../assets/EducationHatIcon.svg";
// import MessageIcon from "../../../assets/MessageIcon.svg";
// import SettingIcon from "../../../assets/SettingIcon.svg";
// import CoursesIcon from "../../../assets/CoursesIcon.svg";
// import ReadingIcon from "../../../assets/ReadingIcon.svg";
// import AssignmentIcon from "../../../assets/AssignmentIcon.svg";
// import Camera from "../../../assets/CameraProfile.svg";
// import OverlayBtn from "../../../assets/OverlayBtn.svg";
// import { HiChevronDown, HiMail, HiBell, HiPencilAlt } from "react-icons/hi";
// import { ReactComponent as Underline } from "../../../assets/Underline.svg";
// import { Link } from "react-router-dom";
import {
  // courseEnroll,
  getACourse,
  getAllCourses,
  getCourseProgress,
  getMeta,
} from "../../../api/course.api";
import { AuthContext } from "../../../context/AuthContext";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { ProgressBar, Spinner } from "react-bootstrap";
import {
  HiBell,
  HiCheckCircle,
  HiChevronDoubleLeft,
  HiMail,
} from "react-icons/hi";

const getIsMobile = () => window.innerWidth <= 768;

function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(getIsMobile());

  React.useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
}

export default function Courses() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { user, onLogout } = useContext<any>(AuthContext);
  const [courses, setCourses] = React.useState([]);
  const [course, setCourse] = React.useState<any>(null);
  const [progress, setProgress] = React.useState<any>([]);

  const [isLoading, setLoading] = React.useState(true);
  const [meta, setMeta] = React.useState({
    users: 0,
    courses: 0,
    assignment: 0,
  });

  const [courseTime, setCourseTime] = React.useState(0);
  const [onMyCourses] = useOutletContext<any>();

  const getCourses = async () => {
    const _c = await getAllCourses();
    setCourses(_c.data);
    if (_c.data.length > 0) {
      setSelecedCourse(_c.data[0].courseID);
      const _course = await getACourse(_c.data[0].courseID);
      setCourse(_course.data);

      const _getProgress = await getCourseProgress(_c.data[0].courseID);
      setProgress(_getProgress);
      onCourseTime(_course.data, _getProgress);
      setLoading(false);
    }
    const metas = await getMeta();
    setMeta({ ...meta, ...metas.data });
  };

  const [selectedCourse, setSelecedCourse] = React.useState<string | null>(
    null
  );
  React.useEffect(() => {
    getCourses();
  }, []);

  const onCourseTime = (course: any, progress: any[]) => {
    // console.log(progress);
    const timer = course.sections.reduce((accumulator: any, sections: any) => {
      return (
        accumulator +
        sections.contents.reduce((accumulator: any, content: any) => {
          if (progress.includes(content.contentID)) return accumulator;
          return accumulator + content.time;
        }, 0)
      );
    }, 0);
    setCourseTime(timer);
  };

  const onCourseChange = async (id: string) => {
    setLoading(true);
    setSelecedCourse(id);
    setCourse({});
    setProgress([]);
    try {
      const _course = await getACourse(id);
      setCourse(_course.data);
      const _getProgress = await getCourseProgress(id);
      setProgress(_getProgress);
      onCourseTime(_course.data, _getProgress);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  // const onEnrollment = async (courseID: string) => {
  //   try {
  //     await courseEnroll(courseID);
  //     toast.success("Enrollment Successfull");
  //     onMyCourses();
  //     navigate(`/courses/${courseID}`);
  //   } catch (error: any) {
  //     toast.error(error?.response?.data?.message ?? "Something Went Wrong");
  //   }
  // };

  return (
    <>
      {!isMobile && (
        <div className="flex-grow flex p-8">
          <Link to="/">
            <HiChevronDoubleLeft className="inline mr-2" />
            Home
          </Link>
        </div>
      )}
      <div className="px-8 py-8 md:py-0 flex flex-row justify-center items-center">
        {isMobile ? (
          <div className="flex-grow flex">
            <Link to="/">
              <HiChevronDoubleLeft className="inline mr-2" />
              Home
            </Link>
          </div>
        ) : (
          <>
            <div className="flex-grow flex flex-row  items-center">
              <div className="rounded-full w-[36px] h-[36px] shadow shadow-primary mr-3">
                <img
                  src={
                    user?.avatar ??
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                  }
                  alt="profile"
                  className="object-cover w-[36px] h-[36px] rounded-full"
                />
              </div>
              welcome{" "}
              <span className="text-primary ml-2">{user?.firstName}</span>
            </div>
          </>
        )}
        <div className="flex justify-end items-center">
          <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
            <HiMail className="text-primary text-[24px]" />
          </button>
          <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
            <HiBell className="text-primary text-[24px]" />
          </button>
          <button
            className="bg-primary mx-2 py-2 px-5 rounded-3xl text-white capitalize font-bold"
            onClick={onLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1">
        <div className=" p-10">
          <h1 className="md:text-xl text-lg font-semibold mb-3 mt-5">
            Hello, {user?.firstName}
          </h1>
          <p className="md:text-3xl text-2xl font-bold">
            Welcome To Elevated Nation Educational Portal
          </p>
          {isLoading ? (
            <div className="my-10">
              <Spinner />
            </div>
          ) : (
            <>
              <p className="mt-10">Course Progress</p>
              <div className="flex justify-between py-4">
                <p className="text-lg font-semibold">{course?.title}</p>
                <p>
                  {(
                    ((course.sections.reduce(
                      (accumulator: any, sections: any) => {
                        return accumulator + sections.contents.length;
                      },
                      0
                    ) -
                      course.sections.reduce(
                        (accumulator: any, sections: any) => {
                          return (
                            accumulator +
                            sections.contents.reduce(
                              (accumulator: any, content: any) => {
                                if (progress.includes(content.contentID))
                                  return accumulator;
                                return accumulator + 1;
                              },
                              0
                            )
                          );
                        },
                        0
                      )) /
                      course.sections.reduce(
                        (accumulator: any, sections: any) => {
                          return accumulator + sections.contents.length;
                        },
                        0
                      )) *
                    100
                  ).toFixed(2)}
                  %
                </p>
              </div>
              <ProgressBar
                now={parseFloat(
                  (
                    ((course?.sections?.reduce(
                      (accumulator: any, sections: any) => {
                        return accumulator + sections.contents.length;
                      },
                      0
                    ) -
                      course?.sections?.reduce(
                        (accumulator: any, sections: any) => {
                          return (
                            accumulator +
                            sections.contents.reduce(
                              (accumulator: any, content: any) => {
                                if (progress.includes(content.contentID))
                                  return accumulator;
                                return accumulator + 1;
                              },
                              0
                            )
                          );
                        },
                        0
                      )) /
                      course?.sections?.reduce(
                        (accumulator: any, sections: any) => {
                          return accumulator + sections.contents.length;
                        },
                        0
                      )) *
                    100
                  ).toFixed(2)
                )}
              />
              <p className="mt-10 text-xs">Estimated completion:</p>{" "}
              <p className="text-lg font-semibold mb-4">{courseTime} mins</p>
              <p className="text-lg py-4">{course?.description}</p>
            </>
          )}
        </div>
        <div className=" p-10 text-center max-h-screen overflow-auto">
          <h3>Get Started</h3>
          <h2 className="md:text-2xl text-xl my-4">
            Click below to explore all courses and lessons on the Elevated
            Nation
          </h2>
          <div className="grid md:grid-cols-3 grid-cols-2 text-left py-10">
            {courses &&
              courses.map((c: any, i) => (
                <button
                  key={i}
                  className="flex flex-row"
                  onClick={() => onCourseChange(c.courseID)}
                >
                  <div
                    className={`h-12 w-12 rounded-full overflow-hidden ${
                      c.courseID === selectedCourse
                        ? "border-2 border-primary"
                        : "border-2 border-transparent"
                    }`}
                  >
                    <img
                      src={c.poster}
                      className="h-12 w-12 object-cover"
                      alt=""
                    />
                  </div>
                  <div className="flex-grow ml-2 flex items-center h-12">
                    <p
                      className={`text-left ${
                        c.courseID === selectedCourse ? "font-bold" : null
                      }`}
                    >
                      {c.title}
                    </p>
                  </div>
                </button>
              ))}
          </div>
          <hr className="opacity-10" />
          {isLoading ? (
            <div className="my-10">
              <Spinner />
            </div>
          ) : (
            <>
              {course?.sections &&
                course?.sections?.map((sec: any, i: number) => (
                  <Fragment key={i}>
                    <h3 className="text-base my-3 font-semibold text-left py-3">
                      {sec.title}
                    </h3>
                    <ul className="grid md:grid-cols-4 grid-cols-2 gap-4">
                      {sec.contents.map((con: any, i: number) => (
                        <li
                          key={i}
                          className={`border-t-4 border-gray-800 text-xs relative text-left bg-gray-900 rounded p-3 flex justify-between flex-col min-h-[200px]`}
                        >
                          <p>{con.title}</p>
                          <Link
                            to={`/courses/${selectedCourse}/${con.contentID}`}
                            className={`p-5 text-xs w-full rounded text-center text-white px-3 py-2 ${
                              progress?.includes(con?.contentID)
                                ? "bg-orange-500"
                                : "bg-primary"
                            }`}
                          >
                            {progress?.includes(con?.contentID)
                              ? "Resume"
                              : "Start"}
                          </Link>
                          {progress?.includes(con?.contentID) ? (
                            <HiCheckCircle className="text-primary inline absolute right-[-10px] top-[-10px] text-xl" />
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  </Fragment>
                ))}
            </>
          )}
        </div>
      </div>
      {/* <div className="max-w-[800px] grid grid-cols-3 gap-4 my-5">
          <div className="p-3">
            <div className="flex flex-row items-center text-2xl font-bold text-[#CFFFB8]">
              <div className="rounded-md bg-[#151515] p-2 mr-3">
                <img src={ReadingIcon} alt="" />
              </div>
              {meta?.users}
            </div>
            <h4 className="text-lg font-semibold">Students</h4>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos, id!
              Cupiditate, dolori.
            </p>
          </div>
          <div className="p-3">
            <div className="flex flex-row items-center text-2xl font-bold text-[#CFFFB8]">
              <div className="rounded-md bg-[#151515] p-2 mr-3">
                <img src={CoursesIcon} alt="" />
              </div>
              {meta?.courses}
            </div>
            <h4 className="text-lg font-semibold">Courses</h4>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos, id!
              Cupiditate, dolori.
            </p>
          </div>
          <div className="p-3">
            <div className="flex flex-row items-center text-2xl font-bold text-[#CFFFB8]">
              <div className="rounded-md bg-[#151515] p-2 mr-3">
                <img src={AssignmentIcon} alt="" />
              </div>
              {meta?.assignment}
            </div>
            <h4 className="text-lg font-semibold">Assignment</h4>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos, id!
              Cupiditate, dolori.
            </p>
          </div>
        </div> */}
      {/* <div className=""></div> */}

      {/*  // <div className="flex flex-row min-h-screen max-h-screen">
    //   <div className="bg-[#151515] min-w-[320px] flex-col">
    //     <div className="h-[150px] flex items-center justify-center">
    //       <img src={Logo} alt="logo" className="w-[180px]" />
    //     </div>
    //     <hr className="opacity-10" />
    //     <ul className="pt-8">
    //       <li className="border-[#ffffff10] text-md">
    //         <Link to="/" className="py-4  px-5 flex items-center">
    //           <img src={HomeIcon} alt="logo" className="w-[24px] mr-3" />
    //           Portal Dashboard
    //         </Link>
    //       </li>

    //       <li className="border-r-4 border-primary text-md bg-[#000] ">
    //         <Link to="/courses" className="py-4  px-5 flex items-center">
    //           <img src={StudentIcon} alt="logo" className="w-[24px] mr-3" />
    //           Education
    //         </Link>
    //       </li>
    //       <li className="border-t border-[#ffffff10] px-5 text-md flex justify-between items-center ">
    //         <Link to="/my-course" className="py-4 flex items-center flex-grow">
    //           <img
    //             src={EducationHatIcon}
    //             alt="logo"
    //             className="w-[24px] mr-3"
    //           />
    //           My Courses
    //         </Link>

    //         <button>
    //           <HiChevronDown className="text-primary text-lg" />
    //         </button>
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Cryptocurrency
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Blockchain
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         NFTs
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Mining
    //       </li>
    //       <li className="border-t border-[#ffffff10] text-md">
    //         <Link to="/message" className="py-4  px-5 flex items-center">
    //           <img src={MessageIcon} alt="logo" className="w-[24px] mr-3" />
    //           Message Board
    //         </Link>
    //       </li>
    //       <li className="border-t border-[#ffffff10] text-md">
    //         <Link to="/settings" className="py-4  px-5 flex items-center">
    //           <img src={SettingIcon} alt="logo" className="w-[24px] mr-3" />
    //           Setting{" "}
    //         </Link>
    //       </li>
    //     </ul>
    //     <hr className="opacity-10" />
    //   </div>
    //   <div className="flex-grow  max-h-screen overflow-auto px-8">
    //     <img src={PTop} alt="" className="absolute right-0 top-0 h-[450px]" />
    //     <div className="py-8 flex flex-row justify-center items-center">
    //       <div className="flex-grow flex flex-row  items-center">
    //         <div className="rounded-full w-[36px] h-[36px] shadow shadow-primary mr-3">
    //           <img
    //             src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
    //             alt="profile"
    //             className="object-cover w-[36px] h-[36px]"
    //           />
    //         </div>
    //         welcome <span className="text-primary ml-2">TIM</span>
    //       </div>
    //       <div className="flex justify-end items-center">
    //         <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
    //           <HiMail className="text-primary text-[24px]" />
    //         </button>
    //         <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
    //           <HiBell className="text-primary text-[24px]" />
    //         </button>
    //         <button className="bg-primary mx-2 py-2 px-5 rounded-3xl text-white capitalize font-bold">
    //           Logout
    //         </button>
    //       </div>
    //     </div>    
 </div>
    </div> */}
    </>
  );
}
