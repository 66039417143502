import React, { useContext } from "react";
import { ReactComponent as BGLay } from "../../../assets/DoggoBG.svg";
import { ReactComponent as Underline } from "../../../assets/Underline.svg";
import Dog from "../../../assets/savagedoglogin.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { login } from "../../../api/auth.api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import { ImGoogle } from "react-icons/im";
// import { Stream } from "@cloudflare/stream-react";

const LoginSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Email Required"),
  password: Yup.string().required("Password Required").min(8, "Too Short!"),
  // .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, "Not Secure Password"),
});

export default function Login() {
  const { setHasToken, setIsAuth } = useContext<any>(AuthContext);

  const navigate = useNavigate();
  const onLogin = async (data: any) => {
    try {
      const respon = await login(data);
      localStorage.setItem("token", respon.data.access_token);
      setHasToken(true);
      setIsAuth(true);
      navigate("/");
      toast.success("Welcome Back");
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="min-h-screen w-full flex flex-row">
      <div className="w-[50%] md:flex justify-center items-center hidden ">
        <BGLay className="m min-h-screen max-h-screen object-cover" />
        <img src={Dog} alt="dog" className="absolute w-[240px]" />
      </div>
      <div className="md:w-[50%] flex items-center w-full px-5 md:px-0">
        <div className="md:max-w-[450px] w-full flex flex-col items-center md:items-start">
          <h2 className="text-lg">
            Welcome to
            <span className="text-primary font-semibold ml-1">Elevated Nation</span>
          </h2>
          <h1 className="text-2xl font-bold mb-3 mt-5">Login</h1>
          <Underline className="mb-5" />
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              onLogin(values);
            }}
          >
            {({ errors, touched, handleChange, values, handleBlur }) => (
              <Form className="w-full">
                <input
                  className="border-[0.4px] border-primary rounded p-3 bg-[#000000] my-2 w-full  text-xs"
                  placeholder="Email"
                  type="email"
                  name="username"
                  onChange={handleChange}
                  value={values["username"]}
                  onBlur={handleBlur}
                />
                {touched["username"] && errors["username"] ? (
                  <p className="mt-0 mb-5 text-[#ff0000] text-xs">
                    {errors["username"]}
                  </p>
                ) : null}
                <input
                  className="border-[0.4px] border-primary rounded p-3 bg-[#000000] w-full my-2 text-xs"
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={values["password"]}
                  onBlur={handleBlur}
                />
                {touched["password"] && errors["password"] ? (
                  <p className="mt-0 mb-5 text-[#ff0000] text-xs">
                    {errors["password"]}
                  </p>
                ) : null}
                <div className="flex flex-row justify-between py-3">
                  <div>
                    <label>
                      <input type="checkbox" /> Remember Me
                    </label>
                  </div>
                  <div>
                    {/* <Link className="text-primary" to="/auth/forgot">
                      Forgot Passsword
                    </Link> */}
                  </div>
                </div>
                <button className="bg-primary rounded my-5 p-3 w-full">
                  Login
                </button>
              </Form>
            )}
          </Formik>
          <button
            className="px-3 py-3 border-[0.5px] border-primary rounded-lg flex items-center justify-center w-full"
            onClick={() =>
              window.location.replace(
                `${process.env.REACT_APP_BACKEND}auth/google`
              )
            }
          >
            <ImGoogle className="inline mr-3 w-5 h-5" /> Continue With Google
          </button>
          <hr className="opacity-20 my-3" />
          <p>
            No Account?{" "}
            <Link to="/auth/register" className="text-primary">
              Register Now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
