import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    // Do something before request is sent
    const token = localStorage.getItem("token");

    if (token !== null) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
