import { axiosInstance } from "./api";

export const login = async (data: any) => {
  return await axiosInstance.post("/auth/login", data);
};

export const register = async (data: any) => {
  return await axiosInstance.post("/auth/register", data);
};

export const googleCallback = async (data: any) => {
  return await axiosInstance.get("/auth/google/callback", {
    params: data,
  });
};
