import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { googleCallback } from "../../../api/auth.api";
import { AuthContext } from "../../../context/AuthContext";

export default function GoogleCallback() {
  // const {code} =
  const [searchParams] = useSearchParams();
  const { setHasToken, setIsAuth } = useContext<any>(AuthContext);
  const navigate = useNavigate();
  //   const [isLoading, setLoading] = useState(true);
  const initCall = async () => {
    if (searchParams.get("code") === undefined) {
      navigate("/auth/login");
      return toast.error("Invalid Process");
    }
    try {
      const response = await googleCallback({
        code: searchParams.get("code"),
        scope: searchParams.get("scope"),
      });
      localStorage.setItem("token", response.data.access_token);
      setHasToken(true);
      setIsAuth(true);
      navigate("/");
    } catch (error) {
      if (process.env.NODE_ENV !== "development") navigate("/auth/login");
      return toast.error("Invalid Process");
    }
  };
  React.useEffect(() => {
    initCall();

    //eslint-disable-next-line
  }, []);

  return <div>Signing In</div>;
}
