import React from "react";
import { ReactComponent as BGLay } from "../../../assets/DoggoBG.svg";
import { ReactComponent as Underline } from "../../../assets/Underline.svg";
import Dog from "../../../assets/savagedoglogin.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { register } from "../../../api/auth.api";
import { toast } from "react-toastify";
import { ImGoogle } from "react-icons/im";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name Required"),
  email: Yup.string().email("Invalid email").required("Email Required"),
  password: Yup.string()
    .required("Password Required")
    .min(8, "Too Short!")
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, "Not Secure Password"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ),
});
export default function Register() {
  

  const navigate = useNavigate();
  const onSignUp = async (data: any) => {
    try {
      await register(data);
      navigate("/auth/login");
      toast.success("Account Created");
    } catch (error) {
      toast.error("Something Went Wrong :(");
    }
  };

  return (
    <div className="min-h-screen w-full flex flex-row">
      <div className="w-[50%] md:flex justify-center items-center hidden">
        <BGLay className="m min-h-screen max-h-screen object-cover" />
        <img src={Dog} alt="dog" className="absolute w-[240px]" />
      </div>
      <div className="md:w-[50%] flex items-center w-full px-5 md:px-0">
        <div className="md:max-w-[450px] w-full flex flex-col items-center md:items-start">
          <h2 className="text-lg">
            Welcome to{" "}
            <span className="text-primary font-semibold">Elevated Nation</span>
          </h2>
          <h1 className="text-2xl font-bold mb-3 mt-5">Signup</h1>
          <Underline className="mb-5" />
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              onSignUp(values);
            }}
          >
            {({ errors, touched, handleChange, values, handleBlur }) => (
              <Form className="w-full">
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <input
                      className="border-[0.4px] border-primary w-full rounded p-3 bg-[#000000]  text-xs"
                      placeholder="First Name"
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      value={values["firstName"]}
                      onBlur={handleBlur}
                    />
                    {touched["firstName"] && errors["firstName"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["firstName"]}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <input
                      className="border-[0.4px] border-primary w-full rounded p-3 bg-[#000000]  text-xs"
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={values["lastName"]}
                      onBlur={handleBlur}
                    />
                    {touched["lastName"] && errors["lastName"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["lastName"]}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="my-3">
                  <input
                    className="border-[0.4px] border-primary w-full rounded p-3 bg-[#000000] text-xs"
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values["email"]}
                    onBlur={handleBlur}
                  />
                  {touched["email"] && errors["email"] ? (
                    <p className="mt-3 text-[#ff0000] text-xs">
                      {errors["email"]}
                    </p>
                  ) : null}
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <input
                      className="border-[0.4px] border-primary w-full rounded p-3 bg-[#000000] text-xs"
                      placeholder="Password"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={values["password"]}
                      onBlur={handleBlur}
                    />
                    {touched["password"] && errors["password"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["password"]}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <input
                      className="border-[0.4px] border-primary w-full rounded p-3 bg-[#000000] text-xs"
                      placeholder="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      onChange={handleChange}
                      value={values["confirmPassword"]}
                      onBlur={handleBlur}
                    />
                    {touched["confirmPassword"] && errors["confirmPassword"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["confirmPassword"]}
                      </p>
                    ) : null}
                  </div>
                </div>
                <button className="bg-primary rounded my-5 p-3 w-full">
                  Register
                </button>{" "}
              </Form>
            )}
          </Formik>
          <button
            className="px-3 py-3 border-[0.5px] border-primary rounded-lg flex items-center justify-center w-full"
            onClick={() =>
              window.location.replace(
                `${process.env.REACT_APP_BACKEND}auth/google`
              )
            }
          >
            <ImGoogle className="inline mr-3 w-5 h-5" /> Continue With Google
          </button>
          <hr className="opacity-50 my-3" />
          <p>
            Have Account?
            <Link to="/auth/register ml-2" className="text-primary ml-2">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
