import React from "react";
import { getAllUser } from "../../../api/user.api";
import { toast } from "react-toastify";
import {
  HiOutlineEye,
  HiOutlineTrash,
  HiOutlineUserRemove,
} from "react-icons/hi";
import { getAllCourses } from "../../../api/course.api";

type Props = {};

export default function MessageHome({}: Props) {
  const [messages, setMessages] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const initCall = async () => {
    try {
      // const users = await getAllMessages();
      setLoading(false);
      // setMessages(users.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  React.useEffect(() => {
    initCall();
  }, []);

  return (
    <div>
      <div className="overflow-hidden rounded-lg border border-gray-900 shadow-md ">
        <table className="w-full border-collapse bg-white text-left text-sm text-gray-900">
          <thead className="bg-gray-400">
            <tr>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Title
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Sections
              </th>
              <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                Contents/Modules
              </th>
              <th
                scope="col"
                className="px-6 py-4 font-medium text-right text-gray-900"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 border-t border-gray-100">
            {messages.map((u: any, i: number) => (
              <tr className="hover:bg-gray-50">
                <th
                  className="flex gap-3 px-6 py-4 font-normal text-gray-900"
                  key={i}
                >
                  <div className="text-sm flex items-center">
                    <div className="font-medium text-gray-700">{u.title}</div>
                  </div>
                </th>

                <td className="px-6 py-4">{u.email}</td>
                <td className="px-6 py-4 text-black">{u.phone ?? "-"}</td>
                <td className="px-6 py-4">
                  <div className="flex justify-end gap-4">
                    <button>
                      <HiOutlineTrash className="h-5 w-5" />
                    </button>
                    <button>
                      <HiOutlineEye className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
