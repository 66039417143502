import { axiosInstance } from "./api";

export const getAllUser = async () => {
  return await axiosInstance.get("/user");
};

export const getMyself = async () => {
  return await axiosInstance.get("/user/me");
};
export const updateMySelf = async (data: any) => {
  return await axiosInstance.patch("/user/me", data);
};

export const uploadProfilePicture = async (data: any) => {
  return await axiosInstance.post("/user/upload", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// export const  = () => {};
