import React from "react";
import {
  // HiChevronDown,
  // HiMail,
  // HiBell,
  // HiSearch,
  HiOutlineCollection,
  // HiOutlineThumbUp,
  // HiOutlineChatAlt,
} from "react-icons/hi";
// import { ReactComponent as Underline } from "../../../assets/Underline.svg";
import {
  //Link,
  // useNavigate,
  useParams,
} from "react-router-dom";
import ReactQuill from "react-quill";
import { Form, Formik } from "formik";
import { answerABoard, getABoard } from "../../../api/board.api";
import { toast } from "react-toastify";
import { formatDistance } from "date-fns";

export default function ViewMessage() {
  // const navigate = useNavigate();
  const { id } = useParams();
  const [board, setBoard] = React.useState<any>({});

  const [isLoading, setIsLoading] = React.useState(true);

  const onCreateAnswer = async (data: any) => {
    try {
      console.log(id);
      await answerABoard(data, id);
      toast.success("Answer Posted");
      getBoardData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getBoardData = async () => {
    try {
      const boards = await getABoard(id);
      setBoard(boards.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getBoardData();
  }, []);

  return !isLoading ? (
    <>
      <div className="my-8">
        <div className="mt-8 mb-4 pb-3 border-b border-primary border-opacity-25">
          <h1 className="text-2xl font-bold mb-3 mt-5">{board.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: board?.content }} />
          <div className="flex justify-between my-4">
            <div className="">
              <div className="flex-grow flex flex-row  items-center font-light">
                <div className="rounded-full w-[28px] h-[28px] shadow shadow-primary mr-3">
                  <img
                    src={
                      board?.user?.avatar ??
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                    }
                    alt="profile"
                    className="object-cover w-[28px] h-[28px]"
                  />
                </div>
                {board?.user?.firstName} {board?.user?.lastName}{" "}
                <span className="text-primary ml-2 text-xs">
                  {" "}
                  · Posted{" "}
                  {formatDistance(
                    new Date(board?.createdAt),
                    new Date(new Date().toISOString())
                  )}{" "}
                  ago
                </span>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            answer: "",
          }}
          // validationSchema={LoginSchema}
          onSubmit={(values) => {
            // onLogin(values);
            onCreateAnswer(values);
          }}
        >
          {({ errors, touched, handleChange, values, handleBlur }) => (
            <Form>
              <ReactQuill
                theme="snow"
                value={values["answer"]}
                onChange={(e) => handleChange("answer")(e)}
              />
              <div className="flex justify-end my-8">
                <button
                  className="bg-primary rounded-3xl py-2 px-5"
                  type="submit"
                >
                  Answer
                </button>
                <button
                  className="border border-primary rounded-3xl py-2 px-5 ml-3"
                  type="reset"
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <h1 className="text-xl font-bold mb-3 mt-5">Answers</h1>
        {board?.answers && board?.answers.length === 0 && (
          <h6 className="text-md">No Answers :)</h6>
        )}
        {board?.answers &&
          board?.answers.map((k: any, i: any) => (
            <div
              className=" border rounded-lg border-[#10CC0050] bg-black flex flex-row my-5 py-5 "
              key={i}
            >
              <div className="w-[36px] p-2">
                <div className="w-[30px] h-[30px] bg-primary rounded-full flex items-center justify-center">
                  <HiOutlineCollection className="w-[20px] h-[20px] text-center" />
                </div>
              </div>
              <div className="flex-grow px-3">
                {/* <h4 className="text-lg font-bold">{k?.title}</h4> */}
                <div
                  className="text-light mb-4 bg-[#ffffff] bg-opacity-20 px-5 py-4 rounded-lg"
                  dangerouslySetInnerHTML={{ __html: k.answer }}
                />
                <div className="flex justify-between">
                  <div className="">
                    <div className="flex-grow flex flex-row  items-center font-light">
                      <div className="rounded-full w-[28px] h-[28px] shadow shadow-primary mr-3">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                          alt="profile"
                          className="object-cover w-[28px] h-[28px]"
                        />
                      </div>
                      {k.user.firstName} {k.user.lastName}{" "}
                      <span className="text-primary ml-2 text-xs">
                        {" "}
                        · Answered{" "}
                        {formatDistance(
                          new Date(k?.createdAt),
                          new Date(new Date().toISOString())
                        )}{" "}
                        ago
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  ) : null;
}
