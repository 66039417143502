import { axiosInstance } from "./api";

export const getAllCourses = async () => {
  return await axiosInstance.get("/course");
};

export const getMeta = async () => {
  return await axiosInstance.get("/course/meta");
};

export const getACourse = async (id: string | any) => {
  return await axiosInstance.get(`/course/${id}`);
};
export const getCourseProgress = async (id: string | any) => {
  const d = await axiosInstance.get(`/course/${id}/progress`);
  return d.data.map((_c: any) => _c.content.contentID);
};
export const updateCourseProgress = async (id: string | any, data: any) => {
  return await axiosInstance.post(`/course/${id}/progress`, data);
};
export const courseEnroll = async (id: string | any) => {
  return await axiosInstance.post(`course/${id}/enroll`);
};
export const checkEnrollment = async (id: string | any) => {
  return await axiosInstance.get(`course/${id}/enroll`);
};
export const getMyCourses = async () => {
  return await axiosInstance.get("/course/my");
};
