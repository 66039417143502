import React, { Fragment, useContext, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
// import { AuthContext } from "../../context/AuthContext";
// import PTop from "../../../assets/PatternTop.svg";
import Logo from "../../../assets/Logo.png";
// import HomeIcon from "../../../assets/HomeIcon.svg";
// import StudentIcon from "../../../assets/StudentIcon.svg";
// import EducationHatIcon from "../../../assets/EducationHatIcon.svg";
// import MessageIcon from "../../../assets/MessageIcon.svg";
// import SettingIcon from "../../../assets/SettingIcon.svg";
import {
  //   HiChevronDown,
  HiMail,
  HiBell,
  HiPlay,
  //   HiCheck,
  HiCheckCircle,
  HiChevronDoubleLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import {
  checkEnrollment,
  getACourse,
  getCourseProgress,
  //   getMyCourses,
  updateCourseProgress,
} from "../../../api/course.api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import { Spinner } from "react-bootstrap";

const getIsMobile = () => window.innerWidth <= 768;

function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(getIsMobile());

  React.useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
}

export default function ViewCourse() {
  const isMobile = useIsMobile();
  const { user, onLogout } = useContext<any>(AuthContext);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams<any>();

  const [course, setCourse] = useState<any>(null);
  const [progress, setProgress] = useState<any>([]);

  const initCall = async () => {
    try {
      // await checkEnrollment(id);
      //   getCourse();
      const _c = await getACourse(id);
      setCourse(_c.data);
      const _getProgress = await getCourseProgress(id);
      setProgress(_getProgress);
      // if (_getProgress.length > 0) {
      //   navigate(`/courses/${id}/${_getProgress[0]}`);
      // } else {
      //   if (_c.data.sections[0] && _c.data.sections[0].contents[0]) {
      //     navigate(
      //       `/courses/${id}/${_c.data.sections[0].contents[0].contentID}`
      //     );
      //   } else {
      //     //   navigate(`/courses/`);
      //   }
      // }
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        navigate("/courses");
      }
    }
  };

  React.useEffect(() => {
    initCall();
    //eslint-disable-next-line
  }, []);

  const onMarkAsCompleted = async (contentID: string) => {
    try {
      await updateCourseProgress(id, { contentID });
      toast.success("Marked As Complete");
      const _getProgress = await getCourseProgress(id);
      setProgress(_getProgress);
    } catch (error: any) {
      toast.error(error.response.data.message ?? "Something Went Wrong");
    }
  };

  return isLoading ? (
    <div className="w-screen h-screen flex justify-center items-center">
      <Spinner />
    </div>
  ) : (
    <>
      {!isMobile && (
        <div className="flex-grow flex p-8">
          <Link to="/">
            <HiChevronDoubleLeft className="inline mr-2" />
            Home
          </Link>
        </div>
      )}
      <div className="flex flex-row min-h-screen max-h-screen">
        {/* <div className="bg-[#151515] min-w-[320px] max-w-[320px] flex-col max-h-screen overflow-auto hidden md:block">
        <div className="h-[100px] bg-[#000000] flex items-center justify-center">
          <img
            src={Logo}
            alt="logo"
            className="w-[100px] cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        {course?.sections &&
          course?.sections.map((sec: any, i: number) => (
            <Fragment key={i}>
              <h3 className="text-xl mx-5 my-3 font-semibold">{sec.title}</h3>
              <ul className="">
                {sec.contents.map((con: any, i: number) => (
                  <li
                    key={i}
                    className={`${
                      window.location.pathname.startsWith(
                        `/courses/${id}/${con.contentID}`
                      )
                        ? "border-r-4 border-primary bg-[#000]"
                        : "border-t border-[#ffffff10]"
                    } text-md relative`}
                  >
                    <Link
                      to={`/courses/${id}/${con.contentID}`}
                      className="py-4  px-5 flex items-center"
                    >
                      <div className="inline rounded-full w-8 h-8 bg-[#fff] bg-opacity-20">
                        <HiPlay className="text-[#fff] w-8 h-8" />
                      </div>
                      <p className="ml-3">{con.title}</p>
                    </Link>
                    {progress?.includes(con?.contentID) ? (
                      <HiCheckCircle className="text-primary inline absolute right-5 top-5 text-xl" />
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </Fragment>
          ))}
        <hr className="opacity-10" />
      </div> */}

        <div className="flex-grow max-h-screen overflow-auto px-8">
          <div className="py-8 md:pt-0 flex flex-row justify-center items-center">
            {isMobile ? (
              <div className="flex-grow flex ">
                <Link to="/">
                  <HiChevronDoubleLeft className="inline mr-2" />
                  Home
                </Link>
              </div>
            ) : (
              <div className="flex-grow flex flex-row  items-center">
                <div className="rounded-full w-[36px] h-[36px] shadow shadow-primary mr-3">
                  <img
                    src={
                      user?.avatar ??
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                    }
                    alt="profile"
                    className="object-cover w-[36px] h-[36px] rounded-full"
                  />
                </div>
                welcome{" "}
                <span className="text-primary ml-2">{user?.firstName}</span>
              </div>
            )}
            <div className="flex justify-end items-center">
              <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
                <HiMail className="text-primary text-[24px]" />
              </button>
              <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
                <HiBell className="text-primary text-[24px]" />
              </button>
              <button
                className="bg-primary mx-2 py-2 px-5 rounded-3xl text-white capitalize font-bold"
                onClick={onLogout}
              >
                Logout
              </button>
            </div>
          </div>
          <Outlet context={[course, onMarkAsCompleted, progress]} />
        </div>
      </div>
    </>
  );
}
