import React from "react";
import {
  HiSearch,
  HiOutlineCollection,
  HiOutlineThumbUp,
  HiOutlineChatAlt,
} from "react-icons/hi";
import { ReactComponent as Underline } from "../../../assets/Underline.svg";
import {
  //Link,
  useNavigate,
} from "react-router-dom";
import ReactQuill from "react-quill";
import { Form, Formik } from "formik";
import {
  getRecentBoard,
  likeBoard,
  postANewBoard,
} from "../../../api/board.api";
import { toast } from "react-toastify";
import {
  formatDistance,
  // format, , formatRelative, subDays
} from "date-fns";

export default function Message() {
  const navigate = useNavigate();
  const [boards, setBoards] = React.useState([]);
  const onCreateNewBoard = async (data: any) => {
    try {
      const board: any = await postANewBoard(data);
      navigate(`/message/${board.data.boardID}`);
      toast.success("Board Created");
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  const getAllLatestBoards = async () => {
    const boards = await getRecentBoard();
    setBoards(boards.data);
  };

  React.useEffect(() => {
    getAllLatestBoards();
  }, []);

  const onLikePress = async (boardID: string) => {
    try {
      await likeBoard(boardID);
      getAllLatestBoards();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    // <div className="flex flex-row min-h-screen max-h-screen">
    //   <div className="bg-[#151515] min-w-[320px] flex-col">
    //     <div className="h-[150px] flex items-center justify-center">
    //       <img src={Logo} alt="logo" className="w-[180px]" />
    //     </div>
    //     <hr className="opacity-10" />
    //     <ul className="pt-8">
    //       <li className="border-[#ffffff10] text-md">
    //         <Link to="/" className="py-4  px-5 flex items-center">
    //           <img src={HomeIcon} alt="logo" className="w-[24px] mr-3" />
    //           Portal Dashboard
    //         </Link>
    //       </li>
    //       <li className="border-t border-[#ffffff10] text-md">
    //         <Link to="/courses" className="py-4  px-5 flex items-center">
    //           <img src={StudentIcon} alt="logo" className="w-[24px] mr-3" />
    //           Education
    //         </Link>
    //       </li>
    //       <li className="border-t border-[#ffffff10] px-5 text-md flex justify-between items-center ">
    //         <Link to="/my-course" className="py-4 flex items-center flex-grow">
    //           <img
    //             src={EducationHatIcon}
    //             alt="logo"
    //             className="w-[24px] mr-3"
    //           />
    //           My Courses
    //         </Link>

    //         <button>
    //           <HiChevronDown className="text-primary text-lg" />
    //         </button>
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Cryptocurrency
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Blockchain
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         NFTs
    //       </li>
    //       <li className="py-3 border-t border-[#ffffff10] pr-5 pl-16 flex items-center text-md">
    //         Mining
    //       </li>
    //       <li className="border-r-4 border-primary text-md bg-[#000] ">
    //         <Link to="/message" className="py-4  px-5 flex items-center">
    //           <img src={MessageIcon} alt="logo" className="w-[24px] mr-3" />
    //           Message Board
    //         </Link>
    //       </li>
    //       <li className="border-t border-[#ffffff10] text-md">
    //         <Link to="/settings" className="py-4  px-5 flex items-center">
    //           <img src={SettingIcon} alt="logo" className="w-[24px] mr-3" />
    //           Setting{" "}
    //         </Link>
    //       </li>
    //     </ul>
    //     <hr className="opacity-10" />
    //   </div>
    //   <div className="flex-grow  max-h-screen overflow-auto px-8">
    //     <img src={PTop} alt="" className="absolute right-0 top-0 h-[450px]" />
    //     <div className="py-8 flex flex-row justify-center items-center">
    //       <div className="flex-grow flex flex-row  items-center">
    //         <div className="rounded-full w-[36px] h-[36px] shadow shadow-primary mr-3">
    //           <img
    //             src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
    //             alt="profile"
    //             className="object-cover w-[36px] h-[36px]"
    //           />
    //         </div>
    //         welcome <span className="text-primary ml-2">TIM</span>
    //       </div>
    //       <div className="flex justify-end items-center">
    //         <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
    //           <HiMail className="text-primary text-[24px]" />
    //         </button>
    //         <button className="bg-[#1B1B1B] mx-2 p-2 rounded">
    //           <HiBell className="text-primary text-[24px]" />
    //         </button>
    //         <button className="bg-primary mx-2 py-2 px-5 rounded-3xl text-white capitalize font-bold">
    //           Logout
    //         </button>
    //       </div>
    //     </div>  </div>
    // </div>
    <>
      <div className="my-8">
        <div className="flex justify-between mt-8 mb-4 items-center">
          <h1 className="text-2xl font-bold mb-3 mt-5">Message Board</h1>
          <div className="w-[200px] relative">
            <input
              className="border-[0.4px] border-primary rounded-lg p-3 w-full  bg-[#000000] my-2  text-xs"
              placeholder="Search..."
              type="text"
            />
            <HiSearch className="absolute right-3 top-5" />
          </div>
        </div>
        <Formik
          initialValues={{
            title: "",
            content: "",
          }}
          // validationSchema={LoginSchema}
          onSubmit={(values) => {
            // onLogin(values);
            onCreateNewBoard(values);
          }}
        >
          {({ errors, touched, handleChange, values, handleBlur }) => (
            <Form>
              <input
                placeholder="Title"
                className="border-[0.4px] border-primary rounded-lg p-3 w-full  bg-[#000000] text-xs mb-4"
                value={values["title"]}
                onChange={(e) => handleChange("title")(e)}
                name="title"
                onBlur={handleBlur}
              />
              <ReactQuill
                theme="snow"
                value={values["content"]}
                onChange={(e) => handleChange("content")(e)}
              />
              <div className="flex justify-end my-8">
                <button
                  className="bg-primary rounded-3xl py-2 px-5"
                  type="submit"
                >
                  Post
                </button>
                <button
                  className="border border-primary rounded-3xl py-2 px-5 ml-3"
                  type="reset"
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <h1 className="text-2xl font-bold mb-3 mt-5">Recent Topics</h1>
        <Underline className="mb-5" />
        {boards.map((k: any, i) => (
          <div
            className=" border-t border-[#10CC0050] bg-black flex flex-row my-5 py-5"
            key={i}
          >
            <div className="w-[36px] p-2">
              <div className="w-[30px] h-[30px] bg-primary rounded-full flex items-center justify-center">
                <HiOutlineCollection className="w-[20px] h-[20px] text-center" />
              </div>
            </div>
            <div className="flex-grow p-3">
              <h4 className="md:text-lg text-sm font-bold">{k?.title}</h4>
              <div
                className="pt-3 text-light"
                dangerouslySetInnerHTML={{ __html: k.content }}
              />
              <div className="flex justify-between flex-col md:flex-row">
                <div className="text-xs md:text-base">
                  <button
                    className=" py-3 mr-3 text-primary font-semibold"
                    onClick={() => onLikePress(k.boardID)}
                  >
                    <HiOutlineThumbUp className="inline" /> Like ({k.likes})
                  </button>
                  <button
                    className=" py-3 mr-3 text-primary font-semibold"
                    onClick={() => navigate(`/message/${k.boardID}`)}
                  >
                    <HiOutlineChatAlt className="inline" /> Reply
                  </button>
                </div>
                <div className="">
                  <div className="flex-grow flex md:flex-row flex-col md:items-center font-light">
                    <div className="flex flex-row  items-center font-light">
                      <div className="rounded-full w-[28px] h-[28px] shadow shadow-primary mr-3">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png"
                          alt="profile"
                          className="object-cover w-[28px] h-[28px]"
                        />
                      </div>
                      {k.user.firstName} {k.user.lastName}{" "}
                    </div>
                    <span className="text-primary md:ml-2 text-xs mt-5">
                      {" "}
                      · Posted{" "}
                      {formatDistance(
                        new Date(k.createdAt),
                        new Date(new Date().toISOString())
                      )}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
