import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import Dashboard from "./pages/Home/Dashboard/Dashboard";
import Courses from "./pages/Home/Courses/Courses";
import Settings from "./pages/Home/Settings/Settings";
import Message from "./pages/Home/Message/Message";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AuthContextWrapper from "./context/AuthContext";
import Home from "./pages/Home/Home";
import Auth from "./pages/Auth/Auth";
// import 'draft-js/dist/Draft.css';
import "react-quill/dist/quill.snow.css";
import ViewMessage from "./pages/Home/Message/ViewMessage";
import ViewCourse from "./pages/Home/Courses/ViewCourse";
import ViewContent from "./pages/Home/Courses/ViewContent";
import AdminHome from "./pages/AdminHome/AdminHome";
import AdminDashboard from "./pages/AdminHome/Home/Home";
import Users from "./pages/AdminHome/Users/Users";
import ViewUser from "./pages/AdminHome/Users/ViewUser";
import CoursesHome from "./pages/AdminHome/Courses/Home";
import CourseView from "./pages/AdminHome/Courses/View";
import MessageHome from "./pages/AdminHome/Message/Home";
import MessageView from "./pages/AdminHome/Message/View";
import GoogleCallback from "./pages/Auth/Callback/GoogleCallback";
import './index.scss';

function App() {
  return (
    <AuthContextWrapper>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Routes>
        {/* <Route path="/"> */}
        <Route path="/" element={<Home />}>
          <Route index element={<Dashboard />} />{" "}
          <Route path="courses">
            <Route index element={<Courses />} />
            <Route path=":id" element={<ViewCourse />}>
              <Route path=":contentID" element={<ViewContent />} />
            </Route>
          </Route>
          <Route path="settings" element={<Settings />} />
          <Route path="message">
            <Route index element={<Message />} />
            <Route path=":id" element={<ViewMessage />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/admin" element={<AdminHome />}>
          <Route index element={<AdminDashboard />} />
          <Route path="users">
            <Route index element={<Users />} />
            <Route path=":id" element={<ViewUser />} />
          </Route>
          <Route path="courses">
            <Route index element={<CoursesHome />} />
            <Route path=":id" element={<CourseView />} />
          </Route>
          <Route path="message">
            <Route index element={<MessageHome />} />
            <Route path=":id" element={<MessageView />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="auth" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="google/callback" element={<GoogleCallback />} />
        </Route>
        {/* <Route path="dashboard" element={<Dashboard />} /> */}

        {/* </Route> */}
      </Routes>
    </AuthContextWrapper>
  );
}

export default App;
