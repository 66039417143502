import React, { useEffect, useState } from "react";

import { getMyself, updateMySelf } from "../../../api/user.api";
import { ReactComponent as Underline } from "../../../assets/Underline.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const SettingsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name Required"),
  email: Yup.string().email("Invalid email").required("Email Required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, "Not Secure Password"),
  nickName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
});

export default function Settings() {
  // const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getMyselfInfo = async () => {
    setIsLoading(true);
    try {
      const _u = await getMyself();
      setIsLoading(false);
      setUser(_u.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMyselfInfo();
  }, []);

  const onUserUpdate = async (data: any) => {
    try {
      // const result: any = {};

      // for (const key in user) {
      //   if (
      //     user.hasOwnProperty(key) &&
      //     // data.hasOwnProperty(key) &&
      //     user[key] !== data[key]
      //   ) {
      //     result[key] = user[key];
      //   }
      // }
      // console.log(result);
      const _u = await updateMySelf(data);
      setUser(_u);
      toast.success("Profile Update");
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      {isLoading ? (
        <p>Loading....</p>
      ) : (
        <Formik
          initialValues={{
            email: user?.email,
            password: "",
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone: user?.phone ?? "",
            address: user?.address ?? "",
            crypto: user?.crypto ?? "",
            nickName: user?.nickName ?? "",
          }}
          validationSchema={SettingsSchema}
          onSubmit={(values) => {
            onUserUpdate({
              ...values,
              password: values.password === "" ? undefined : values.password,
            });
          }}
        >
          {({ errors, touched, handleChange, values, handleBlur }: any) => (
            <Form>
              <div className="">
                <h1 className="text-2xl font-bold mb-3 mt-5">Edit Profile</h1>
                <Underline className="mb-5" />

                <div className="grid grid-col-4 gap-4">
                  <div className="md:col-span-1 col-span-4">
                    <h4 className="text-lg font-semibold">First Name</h4>
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="First Name"
                      type="text"
                      value={values["firstName"]}
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["firstName"] && errors["firstName"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["firstName"]}
                      </p>
                    ) : null}
                  </div>
                  <div className="md:col-span-1 col-span-4">
                    <h4 className="text-lg font-semibold">Last Name</h4>
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="Last Name"
                      type="text"
                      value={values["lastName"]}
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["lastName"] && errors["lastName"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["lastName"]}
                      </p>
                    ) : null}
                  </div>
                  <div className="md:col-span-1 col-span-4">
                    <h4 className="text-lg font-semibold">E-mail</h4>
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="Email"
                      type="email"
                      value={values["email"]}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["email"] && errors["email"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["email"]}
                      </p>
                    ) : null}
                  </div>
                  {/* {JSON.stringify(user)} */}
                  <div className="md:col-span-1 col-span-4">
                    <h4 className="text-lg font-semibold">Password</h4>
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="Password"
                      type="password"
                      value={values["password"]}
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["password"] && errors["password"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["password"]}
                      </p>
                    ) : null}
                  </div>
                  <div className="md:col-span-1 col-span-4">
                    <h4 className="text-lg font-semibold">Nick Name</h4>
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="John"
                      type="text"
                      value={values["nickName"]}
                      name="nickName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["nickName"] && errors["nickName"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["nickName"]}
                      </p>
                    ) : null}
                  </div>
                  <div className="md:col-span-1 col-span-4">
                    <h4 className="text-lg font-semibold">Phone</h4>
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="(123) 4560 789"
                      type="number"
                      value={values["phone"]}
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["phone"] && errors["phone"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["phone"]}
                      </p>
                    ) : null}
                  </div>
                  <div className="md:col-span-2 col-span-4">
                    <h4 className="text-lg font-semibold">Address</h4>
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="ABC, Zone 3"
                      type="text"
                      value={values["address"]}
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["address"] && errors["address"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["address"]}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-span-4">
                    <h4 className="text-lg font-semibold inline">
                      Link Crypto Wallet{" "}
                    </h4>
                    ( BTC, USDT, Litcoin )
                    <input
                      className="border-[0.4px] border-primary rounded p-3 w-full bg-[#000000] my-2  text-xs"
                      placeholder="XXXXXXXX"
                      type="text"
                      value={values["crypto"]}
                      name="crypto"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched["crypto"] && errors["crypto"] ? (
                      <p className="mt-3 text-[#ff0000] text-xs">
                        {errors["crypto"]}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="flex justify-end my-8">
                  <button
                    className="bg-primary rounded-3xl py-2 px-5"
                    type="submit"
                  >
                    Edit Save
                  </button>
                  <button
                    className="border border-primary rounded-3xl py-2 px-5 ml-3"
                    type="reset"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
