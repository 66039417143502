import React, { Fragment } from "react";
import {
  // Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
// import { Stream } from "@cloudflare/stream-react";
import {
  // HiCheckCircle,
  HiChevronDoubleRight,
  // HiChevronDown,
  // HiPlay,
} from "react-icons/hi";
// @ts-ignore
import Quiz from "react-quiz-component";

const getIsMobile = () => window.innerWidth <= 768;

function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(getIsMobile());

  React.useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
}

export default function ViewContent() {
  const navigate = useNavigate();
  const { contentID, id } = useParams();
  const [content, setContent] = React.useState<any>({});

  const [course, onMarkAsCompleted, progress] = useOutletContext<any>();

  // const [tab, setTab] = React.useState(1);
  // const isMobile = useIsMobile();
  React.useEffect(() => {
    if (course === null) {
      return;
    }

    for (let index = 0; index < course?.sections.length; index++) {
      const element = course?.sections[index];
      const _c = element.contents.filter((t: any) => t.contentID === contentID);
      if (_c.length > 0) {
        setContent(_c[0]);
        return;
      }
    }
  }, [course, contentID]);

  // const [sectionSelected, setSectionSelected] = React.useState<null | number>(
  //   null
  // );

  // React.useEffect(() => {
  //   if (isMobile) {
  //     setTab(1);
  //   }
  // }, [isMobile]);

  const nextPrev = () => {
    const sections = course.sections.map((sec: any) => {
      return [...sec.contents];
    });
    var section = 0,
      itemPos = 0;
    for (let index = 0; index < sections.length; index++) {
      const elements = sections[index];
      const itemIndex: number = elements.findIndex((object: any) => {
        return object.contentID === contentID;
      });
      if (itemIndex !== -1) {
        section = index;
        itemPos = itemIndex;
      }

      // console.log(index, itemIndex);
    }
    const isNext = () => {
      if (
        sections.length - 1 === section &&
        sections[section].length - 1 === itemPos
      ) {
        return false;
      }
      return true;
    };
    const isPrev = () => {
      if (section === 0 && itemPos === 0) {
        return false;
      }
      return true;
    };
    const onNext = () => {
      if (sections[section].length - 1 === itemPos) {
        return sections[section + 1][0];
      } else return sections[section][itemPos + 1];
    };
    const onPrev = () => {
      if (itemPos === 0) {
        return sections[section - 1][0];
      } else return sections[section][itemPos - 1];
    };
    const whatPrev = () => {
      if (itemPos === 0) {
        return sections[section - 1][0].title;
      } else return sections[section][itemPos - 1].title;
    };
    const whatNext = () => {
      if (sections[section].length - 1 === itemPos) {
        return sections[section + 1][0].title;
      } else return sections[section][itemPos + 1].title;
    };
    return {
      isNext,
      isPrev,
      onNext,
      onPrev,
      whatNext,
      whatPrev,
    };
  };
  const quiz = {
    quizTitle: "End Of Lesson",
    quizSynopsis:
      "It's time to test your knowledge! See if you can answer these questions",
    nrOfQuestions: "1",
    questions: [
      {
        question:
          "How can you access the state of a component from inside of a member function?",
        questionType: "text",
        answerSelectionType: "single",
        answers: [
          "this.getState()",
          "this.prototype.stateValue",
          "this.state",
          "this.values",
        ],
        correctAnswer: "3",
        messageForCorrectAnswer: "Correct answer. Good job.",
        messageForIncorrectAnswer: "Incorrect answer. Please try again.",
        explanation:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        point: "20",
      },
    ],
  };
  const setQuizResult = (obj: any) => {
    console.log(obj);
    onMarkAsCompleted(content.contentID);
    // YOUR LOGIC GOES HERE
  };

  return (
    <>
      <p className="text-[12px] py-3 font-thin hidden md:block">
        <span>
          My Courses <HiChevronDoubleRight className="inline" /> {course?.title}{" "}
          <HiChevronDoubleRight className="inline" /> {content?.title}
        </span>
      </p>
      <div className="grid grid-cols-12 gap-5">
        <div className="md:col-span-8 col-span-12">
          {/* <h1 className="text-xl font-semibold my-3">Lesson</h1> */}
          <h3 className="text-2xl">{content?.title}</h3>
          {/* <hr className="opacity-20 my-5" /> */}
          <div className=" my-5">
            {/* <Stream
          controls
          src={content?.videoURL}
          onEnded={() => onMarkAsCompleted(content.contentID)}
        /> */}
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                src={`https://customer-0exk640rg41m5e7x.cloudflarestream.com/${content?.videoURL}/iframe`}
                style={{
                  border: "none",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
                title={content?.title}
              ></iframe>
            </div>
          </div>
          <hr className="opacity-10 my-5" />
          {/* {isMobile ? (
            <div className="text-xs my-5">
              <button
                className={`py-2 border border-primary ${
                  tab === 1 ? "bg-primary text-[white]" : ""
                } rounded-3xl px-3 mr-3`}
                onClick={() => setTab(1)}
              >
                Course Content
              </button>
              <button
                className={`py-2 border border-primary ${
                  tab === 2 ? "bg-primary text-[white]" : ""
                } rounded-3xl px-3`}
                onClick={() => setTab(2)}
              >
                Transcript
              </button>
            </div>
          ) : null} */}
          {/* {isMobile && tab === 1 ? (
            <>
              {course?.sections &&
                course?.sections.map((sec: any, i: number) => (
                  <Fragment key={i}>
                    <div className="flex flex-row justify-between items-center bg-[#fff] bg-opacity-20">
                      <h3 className="mx-5 my-3 font-semibold">{sec.title}</h3>
                      <button
                        className="px-3"
                        onClick={() => {
                          if (sectionSelected === i) {
                            debugger;
                            setSectionSelected(null);
                          } else {
                            setSectionSelected(i);
                          }
                        }}
                      >
                        <HiChevronDown className="w-6 h-6" />
                      </button>
                    </div>
                    {sectionSelected === i ? (
                      <ul className="">
                        {sec.contents.map((con: any, i: number) => (
                          <li
                            key={i}
                            className={`${
                              window.location.pathname.startsWith(
                                `/courses/${id}/${con.contentID}`
                              )
                                ? "border-r-4 border-primary bg-[#fff] bg-opacity-10"
                                : "border-t border-[#ffffff10]"
                            } text-md relative`}
                          >
                            <Link
                              to={`/courses/${id}/${con.contentID}`}
                              className="py-4  px-5 flex items-center"
                            >
                              <div className="inline rounded-full w-8 h-8 bg-[#fff] bg-opacity-20">
                                <HiPlay className="text-[#fff] w-8 h-8" />
                              </div>
                              <p className="ml-3">{con.title}</p>
                            </Link>
                            {progress?.includes(con?.contentID) ? (
                              <HiCheckCircle className="text-primary inline absolute right-5 top-5 text-xl" />
                            ) : (
                              ""
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </Fragment>
                ))}
            </>
          ) : null} */}
          {/* {(isMobile && tab === 2) || !isMobile ? ( */}
          <>
            <h4 className="font-semibold my-5">Transcript</h4>
            {content.description ? (
              <div
                dangerouslySetInnerHTML={{ __html: content.description }}
                className="trans_box"
              />
            ) : (
              <i className="opacity-50">Empty</i>
            )}
          </>
          {/* // ) : null} */}
          <div className="bg-white bg-opacity-70 rounded-lg text-black py-3">
            <Quiz quiz={quiz} onComplete={setQuizResult} />
          </div>
          <hr className="opacity-10 mt-5 mb-20 md:my-5" />
          <div
            className={`flex justify-between py-5 ${
              console
              // isMobile
              //   ? "fixed bottom-0 left-0 w-full px-3 bg-[white] bg-opacity-10 backdrop-blur-sm"
              //   : null
            }`}
          >
            {/* <button
              className={`bg-primary py-2 px-5 rounded-3xl text-white capitalize font-bold ${
                isMobile ? "w-full" : null
              }`}
              onClick={() => onMarkAsCompleted(content.contentID)}
            >
              Mark As Completed
            </button> */}{" "}
            {nextPrev().isPrev() ? (
              <button
                className="p-5 text-left border-2 border-primary md:w-48 w-36 rounded-lg shadow"
                onClick={() =>
                  navigate(
                    `/${course.courseID}/${nextPrev().onPrev().contentID}`
                  )
                }
              >
                <p className="text-xs font-bold">Previous</p>
                <hr className="opacity-30 my-3" />
                <p className="text-xs md:text-md">{nextPrev().whatPrev()}</p>
              </button>
            ) : (
              <div />
            )}
            {nextPrev().isNext() ? (
              <button
                className="p-5 text-right border-2 border-primary md:w-48 w-36 rounded-lg shadow"
                onClick={() =>
                  navigate(
                    `/${course.courseID}/${nextPrev().onNext().contentID}`
                  )
                }
              >
                <p className="text-xs font-bold">Up Next</p>
                <hr className="opacity-30 my-3" />
                <p className="text-xs md:text-md">{nextPrev().whatNext()}</p>
              </button>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="md:col-span-4 col-span-12">
          <div className="flex justify-between mb-5">
            <div>
              <p className="text-xs text-gray-400">Estimated reading time:</p>
              <p className="font-semibold">{content.time} minutes</p>
            </div>
            <div>
              Difficulty:
              <p>
                <span
                  className={`w-2 h-2 rounded-full inline-block border ${
                    content.level > 0 ? "border-primary bg-primary" : null
                  } mr-3`}
                ></span>
                <span
                  className={`w-2 h-2 rounded-full inline-block border ${
                    content.level > 1 ? "border-primary bg-primary" : null
                  } mr-3`}
                ></span>
                <span
                  className={`w-2 h-2 rounded-full inline-block border ${
                    content.level > 2 ? "border-primary bg-primary" : null
                  } mr-3`}
                ></span>
                <span
                  className={`w-2 h-2 rounded-full inline-block border ${
                    content.level > 3 ? "border-primary bg-primary" : null
                  } mr-3`}
                ></span>
                <span
                  className={`w-2 h-2 rounded-full inline-block border ${
                    content.level > 4 ? "border-primary bg-primary" : null
                  } mr-3`}
                ></span>
              </p>
            </div>
          </div>
          <div className="my-8">
            <p className="mb-3">Concepts covered: </p>
            <span className="inline-block text-xs mb-3 mr-3 rounded-full px-4 py-2 bg-primary text-white">
              Consensus
            </span>
            <span className="inline-block text-xs mb-3 mr-3 rounded-full px-4 py-2 bg-primary text-white">
              Proof-of-stake
            </span>
            <span className="inline-block text-xs mb-3 mr-3 rounded-full px-4 py-2 bg-primary text-white">
              Proof-of-work
            </span>
            <span className="inline-block text-xs mb-3 mr-3 rounded-full px-4 py-2 bg-primary text-white">
              Trustless Validation
            </span>
          </div>
          <div className="border rounded border-primary p-3 key_eyad">
            <span className="text-xs text-gray-400 mb-3 block">
              Key takeaway:
            </span>
            <p>
              Once 80% of trusted validators agree that a transaction is valid,
              it’s added to the ledger.
            </p>
            <ul>
              <li>
                Transactions change the state of the ledger and must be agreed
                upon by 80% of validators.
              </li>
              <li>
                Validators indicate trust by adding other validators to their
                Unique Node List (UNL).
              </li>
              <li>
                Thanks to the XRP Ledger’s unique consensus protocol,
                transaction fees usually cost less than a hundredth of a penny
                at the time this was written.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
